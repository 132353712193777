
































































































import { Component, Vue } from 'vue-property-decorator'
import { ResetModel } from './models/ResetModel'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

import BaseButton from '@/components/base/BaseButton.vue'
import FormGroup from '@/components/forms/FormGroup.vue'
import Field from '@/components/forms/Field.vue'
import Modal from '@/components/Modal.vue'
import AuthService from '@/modules/AuthPage/AuthService'

const validations = {
  resetModel: {
    password: { required, minLength: minLength(6) },
    password_confirmation: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs('password'),
    },
  },
}

@Component({
  components: { FormGroup, Field, BaseButton, Modal },
  metaInfo: {
    title: 'Установка пароля',
  },
  validations,
})
export default class ResetPasswordPage extends Vue {
  public tokenError = false
  public resetSuccess = false
  private resetModel: ResetModel = new ResetModel()
  private resetToken: string | (string | null)[] = this.$route.query.token

  async checkResetToken(): Promise<void> {
    let result = await AuthService.checkResetToken(this.resetToken)
    if (result === null) {
      this.tokenError = true
    } else {
      this.resetModel.email = result
      this.resetModel.token = this.resetToken
    }
  }

  async resetPassword(): Promise<void | boolean> {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return false
    } else {
      const result = await AuthService.resetPassword(this.resetModel)

      if (result.status && result.status === 200) {
        this.resetSuccess = true
      } else {
        this.tokenError = true
      }
    }
  }

  mounted() {
    this.checkResetToken()
  }
}
